.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: var(--bk1);
    overflow-y: scroll;
}

.loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 3rem;
    color: var(--primaryM);
    background-color: var(--bk1);
}

.fatura {
    display: flex;
    flex-direction: column;
    width: 770px;
    border-radius: 0.5rem;
    margin: 3rem 0;
    background-color: var(--bk1);
    box-shadow: var(--shadow);
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 1rem 1rem 0rem 1rem;
    color: var(--textL);
}

.header-logo-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    height: 100px;
    border-radius: 6rem;
    margin-right: 1rem;
    overflow: hidden;
}

.header-logo {
    position: absolute;
    height: 100%;
}

.header-texts {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.empresa {
    font-size: 1.5rem;
    font-weight: 500;
}

.cnpj {
    font-size: 1rem;
    font-weight: 400;
}

.status-pagamento {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    border-top: 1px solid var(--divider);
    border-bottom: 1px solid var(--divider);
    margin: 1rem 0;
}

.status-pagamento a {
    text-align: right;
}

.container-dados {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    width: 100%;
    border-top: 1px solid var(--divider);
    border-bottom: 1px solid var(--divider);
    margin: 1rem 0;
}

.title-absolute {
    padding: 0rem 0.3rem;
    font-weight: 500;
    background-color: var(--bk1);
}

.title-absolute-termos {
    padding: 0rem 0.3rem;
    padding-left: 1.25rem;
    font-weight: 500;
    font-size: 0.925rem;
    background-color: var(--bk1);
}

.subtitle {
    font-size: 0.8rem;
    font-weight: 400;
    color: var(--textL);
}

.dados-fatura {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 1rem 0;
}

.box-dados-fatura {
    display: flex;
    flex-direction: column;
    margin-right: 5rem;
}

.box-dados-fatura:last-child {
    margin-right: 0rem;
}

.box-dados-fatura {
    display: flex;
    flex-direction: column;
    margin-right: 5rem;
}

.valores-dados {
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--primaryM);
}

.descricao {
    text-align: justify;
    text-align-last: left;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.5rem;
}

.dados-comprador {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem;
    border-top: 1px solid var(--divider);
    border-bottom: 1px solid var(--divider);
    margin: 1rem 0;
}

.info-comprador {
    display: flex;
    flex-direction: column;
    margin: 0.5rem 0;
}

.info-comprador span {
    font-size: 1rem;
    font-weight: 400;
}

.selecione-pagamento {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    width: 100%;
    border-top: 1px solid var(--divider);
    margin: 1rem 0;
}

.btns-pagamento {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin: 0.5rem 0;
    width: 100%;
}

.footer {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2rem;
    border-top: 1px solid var(--divider);
    margin-top: 1rem;
}

.info-fatura {
    width: 100%;
    text-align: justify;
    text-align-last: left;
    font-size: 0.8rem;
    font-weight: 400;
    padding-bottom: 1rem;
    /* border-bottom: 1px solid var(--divider); */
    /* margin-bottom: 1rem; */
}

.info-fatura b {
    font-weight: 500;
}

.txt-link {
    font-size: 0.8rem;
    font-weight: 400;
    margin-bottom: 1rem;
}

.copyright {
    text-transform: none;
    color: var(--primaryL);
}

.alert-recurrence {
    background-color: #fef9c3;
    margin: 0.5rem 0;
    padding: 0.75rem;
    border-radius: 8px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;

    font-size: 0.9rem;
}

.alert-recurrence strong {
    font-weight: 500;
}

.termos-e-condicoes {
    display: flex;
    gap: 0.5rem;
    width: 100%;
    padding: 0 1rem;
    padding-left: 1.25rem;
    margin: 0.75rem 0;
    font-size: 0.875rem;
}

.termos-e-condicoes div span {
    color: rgb(18, 105, 237);
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 2px; /* distância entre o texto e o sublinhado */
}

@media (max-width: 770px) {

    .fatura {
        width: 100%;
        margin: 0;
    }


    .box-dados-fatura {
        display: flex;
        flex-direction: column;
        margin-right: 3rem;
    }
}