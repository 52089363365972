.botao{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: fit-content;
    padding: 0.5rem 1rem;
    border: 1px solid transparent;
    border-radius: 0.3rem;
    font-size: 1rem;
    margin: 0.5rem;
    cursor: pointer;
}
.botao:disabled{
    cursor: not-allowed;
    opacity: 0.3;
}

.botao svg{
    font-size: 1.5em;
}

.botao span{
    font-size: 1em;
    font-weight: 500;
}

/* CORES PADRÃO */

.primary{
    color: var(--textCP);
    background-color: var(--primaryM);
    transition: all 0.3s;
}
.primary:hover{
    background-color: var(--primaryD);
}
.primary:disabled{
    background-color: var(--primaryL);
}

.secondary{
    color: var(--textCSe);
    background-color: var(--secondaryM);
    transition: all 0.3s;
}
.secondary:hover{
    background-color: var(--secondaryD);
}
.secondary:disabled{
    background-color: var(--secondaryL);
}

.error{
    color: var(--textCE);
    background-color: var(--errorM);
    transition: all 0.3s;
}
.error:hover{
    background-color: var(--errorD);
}
.error:disabled{
    background-color: var(--errorL);
}

.warning{
    color: var(--textCW);
    background-color: var(--warningM);
    transition: all 0.3s;
}
.warning:hover{
    background-color: var(--warningD);
}
.warning:disabled{
    background-color: var(--warningL);
}

.info{
    color: var(--textCI);
    background-color: var(--infoM);
    transition: all 0.3s;
}
.info:hover{
    background-color: var(--infoD);
}
.info:disabled{
    background-color: var(--infoL);
}

.success{
    color: var(--textCSu);
    background-color: var(--successM);
    transition: all 0.3s;
}
.success:hover{
    background-color: var(--successD);
}
.success:disabled{
    background-color: var(--successL);
}

/* CORES GHOST */

.primary-g{
    color: var(--primaryM);
    border: 1px solid var(--primaryM);
    background-color: transparent;
    transition: all 0.3s;
}
.primary-g:hover{
    color: var(--textCP);
    border: 1px solid var(--primaryM);
    background-color: var(--primaryM);
}
.primary-g:disabled{
    color: var(--primaryL);
    border: 1px solid var(--primaryL);
    background-color: transparent;
}

.secondary-g{
    color: var(--secondaryM);
    border: 1px solid var(--secondaryM);
    background-color: transparent;
    transition: all 0.3s;
}
.secondary-g:hover{
    color: var(--textCSe);
    border: 1px solid var(--secondaryM);
    background-color: var(--secondaryM);
}
.secondary-g:disabled{
    color: var(--secondaryL);
    border: 1px solid var(--secondaryL);
    background-color: transparent;
}

.error-g{
    color: var(--errorM);
    border: 1px solid var(--errorM);
    background-color: transparent;
    transition: all 0.3s;
}
.error-g:hover{
    color: var(--textCE);
    border: 1px solid var(--errorM);
    background-color: var(--errorM);
}
.error-g:disabled{
    color: var(--errorL);
    border: 1px solid var(--errorL);
    background-color: transparent;
}

.warning-g{
    color: var(--warningM);
    border: 1px solid var(--warningM);
    background-color: transparent;
    transition: all 0.3s;
}
.warning-g:hover{
    color: var(--textCW);
    border: 1px solid var(--warningM);
    background-color: var(--warningM);
}
.warning-g:disabled{
    color: var(--warningL);
    border: 1px solid var(--warningL);
    background-color: transparent;
}

.info-g{
    color: var(--infoM);
    border: 1px solid var(--infoM);
    background-color: transparent;
    transition: all 0.3s;
}
.info-g:hover{
    color: var(--textCI);
    border: 1px solid var(--infoM);
    background-color: var(--infoM);
}
.info-g:disabled{
    color: var(--infoL);
    border: 1px solid var(--infoL);
    background-color: transparent;
}

.success-g{
    color: var(--successM);
    border: 1px solid var(--successM);
    background-color: transparent;
    transition: all 0.3s;
}
.success-g:hover{
    color: var(--textCSu);
    border: 1px solid var(--successM);
    background-color: var(--successM);
}
.success-g:disabled{
    color: var(--successL);
    border: 1px solid var(--successL);
    background-color: transparent;
}