.container{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 1rem;
}

.title{
    font-size: 1.1rem;
    font-weight: 400;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid var(--divider);
}

.subtitle{
    font-size: 1rem;
    font-weight: 300;
    color: var(--textL);
}

.form-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.form{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.bandeiras{
    margin: 0.2rem;
    height: 25px;
}

.bandeira-img{
    pointer-events: none;
    position: absolute;
    right: 20px;
    height: 30px;
    width: 30px;
}

.ssl{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 450px;
    margin-left: 1rem;
    border-left: 1px solid var(--divider);
}

.txt-termos{
    font-size: 0.6rem;
    text-align: justify;
    text-align-last: center;
    margin: 1rem;
    color: var(--textL);
}

.cartoes-container {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 1rem;
    max-height: 100%;
}

.cartao-container {
    width: 100%;
    max-width: 400px;
    display: flex;
    align-items: center;
    gap: 0.875rem;
    background-color: #fff;

    user-select: none; /* Para navegadores modernos */
    -webkit-user-select: none; /* Para Safari */
    -moz-user-select: none; /* Para Firefox */
    -ms-user-select: none; /* Para Internet Explorer/Edge */
}

.cartao-container svg {
    cursor: pointer;
}

.cartao-container::selection {
    color: none;
    background-color: none;
}

.cartao-container:not(:last-child) {
    margin-bottom: 1.5rem;
}

.cartao {
    border: 1px solid #ccc;
    border-radius: 6px;

    display: flex;
    justify-content: start;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
}

.secondary {
    font-size: 0.875rem;
    color: #777;
}

@media (max-width: 770px) {
    .form-container{
        flex-wrap: wrap;
    }
    .ssl{
        margin-left: 0rem;
        border-left: none;
    }
}